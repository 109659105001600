import { GridColumns } from '@material-ui/data-grid';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'Id',
    hide: true,
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'dta_cadastro',
    headerName: 'Data do Envio',
    width: 180,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'cod_status',
    headerName: 'Status',
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'mensagem',
    headerName: 'Mensagem',
    width: 300,
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'recibo',
    headerName: 'Recibo',
    width: 180,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'protocolo',
    headerName: 'Protocolo',
    width: 180,
    sortable: false,
    disableColumnMenu: true,
  },
];

export default columns;
