import React from 'react';
import { GuiaST } from './GuiaST';

export const Guia: React.FC = () => {
  return (
    <>
      <GuiaST />
    </>
  );
};
