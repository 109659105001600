import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Select, Condicao, Finalizadora } from '~/pages/EntradaNFE/protocols';

import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
// import InputMoney from '~/components/Inputs/InputMoney';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { InputMoney } from '~/components/NovosInputs';

export const FormFinanceiro: React.FC = () => {
  const { formNfe, canUpdate, parcelas } = useEntradaNFE();
  const [total, setTotal] = useState(0);

  const { register } = formNfe;

  useEffect(() => {
    let totalParcelas = 0;
    parcelas.map((parcela: any) => {
      totalParcelas += transformAsCurrency(parcela.val_parcela);
      return parcela;
    });
    setTotal(totalParcelas);
  }, [parcelas]);

  return (
    <>
      <Row className="d-flex align-items-end justify-content-between">
        <Col md={6} sm={6}>
          <Row>
            <Col sm={6} md={4}>
              <InputMoney
                label="Desconto"
                placeholder="0,00"
                min={0}
                decimals={2}
                name="val_desc_financeiro"
                register={register}
                // isError={!!errors.money}
                disabled={!canUpdate}
                isError={false}
                showIcon
              />
            </Col>
            <Col sm={6} md={4}>
              <InputMoney
                label="Encargos"
                placeholder="0,00"
                min={0}
                decimals={2}
                name="val_enc_financeiro"
                register={register}
                // isError={!!errors.money}
                disabled={!canUpdate}
                isError={false}
                showIcon
              />
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <h5
            style={{
              textAlign: 'right',
              display: 'block',
            }}
          >
            {`Total Financeiro: R$${formatCurrencyAsText(total)}`}
          </h5>
        </Col>
      </Row>
    </>
  );
};
