import styled from 'styled-components';

export const ButtonItens = styled.button`
  display: flex;
  background: none;
  border: none;
  gap: 5px;
  align-items: center;
  height: 40px;
  padding: 10px;
  margin-top: 10px;
  &:disabled {
    cursor: not-allowed;
  }
  svg {
    font-size: 16pt;
  }
  span {
    color: rgb(107, 119, 140);
  }
  &:hover {
    border: #4c9aff;
  }
`;
