import React, { ChangeEvent } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
// import InputMoney from '~/components/Inputs/InputMoney';
import Separator from '~/components/Separator';
import { InpuTypeDate } from '~/components/Pessoa/styles';
import { CgChevronDoubleDown } from 'react-icons/cg';
import { ConfirmButton } from '~/components/Buttons';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import {
  InputDate,
  InputMaskCnpj,
  InputMoney,
  InputText,
  InputText2,
} from '~/components/NovosInputs';

export const GuiaST: React.FC = () => {
  const { formNfe, canUpdate } = useEntradaNFE();

  const { register, control } = formNfe;

  const onDareClick = () => {
    window.open(
      'https://portal.fazenda.sp.gov.br/servicos/dare/Paginas/DARE-ICMS.aspx',
      '_blank',
    );
  };

  return (
    <>
      <Separator labelText="Guia ST" sidePadding="0 0" />
      <Row className="d-flex align-items-center">
        <Col
          sm={1}
          md={1}
          lg={1}
          xl={1}
          style={{
            marginTop: '2.125rem',
            color: '#3636FE',
            textAlign: 'right',
          }}
        >
          <h6>Fornecedor</h6>
        </Col>
        <Col sm={2} md={2} lg={2} xl={2}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_guia_st_forn"
            register={register}
            // isError={!!errors.money}
            disabled={!canUpdate}
            isError={false}
            showIcon
          />
        </Col>
        <Col sm={2} md={2}>
          <InputDate
            label="Data do Pagamento"
            register={register}
            name="dta_vencto_guia_st_forn"
            isError={false}
            disabled={!canUpdate}
            control={control}
          />
        </Col>

        <Col sm={2} md={2}>
          <InputDate
            label="Data do Recolhimento"
            register={register}
            name="dta_rec_guia_st_forn"
            isError={false}
            disabled={!canUpdate}
            control={control}
          />
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col
          sm={1}
          md={1}
          lg={1}
          xl={1}
          style={{
            marginTop: '2.125rem',
            color: '#FE4747',
            textAlign: 'right',
          }}
        >
          <h6>SEFAZ</h6>
        </Col>
        <Col sm={2} md={2} lg={2} xl={2}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_guia_st_sefaz"
            register={register}
            disabled={!canUpdate}
            // isError={!!errors.money}
            isError={false}
            showIcon
          />
        </Col>
        <Col sm={2} md={2}>
          <InputDate
            label="Data do Pagamento"
            register={register}
            name="dta_vencto_guia_sefaz"
            isError={false}
            disabled={!canUpdate}
            control={control}
          />
        </Col>
        <Col
          sm={2}
          md={2}
          className="align-self-end d-flex align-items-center justify-content-center"
        >
          <ConfirmButton onClick={onDareClick}>
            <CgChevronDoubleDown
              size={24}
              style={{
                marginRight: '0.275rem',
              }}
            />
            DARE
          </ConfirmButton>
        </Col>
      </Row>
    </>
  );
};
