import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { TableContainer } from './styles';
import columns from './columns';

import { InputText } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';
import api from '~/services/api';
import { useEntradaNFE } from '../../EntradaNFEContext';

const Logs: React.FC = () => {
  const { codXml, numChaveAcesso, formNfe } = useEntradaNFE();

  const { register, control, formState } = formNfe;
  const { errors } = formState;

  const [logs, setLogs] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      if (codXml) {
        const res = await api.get(`/entrada-nfe/log/${codXml}`);
        const { success, data } = res.data;
        if (success) {
          setLogs(data);
        }
      }
    })();
  }, [codXml]);

  return (
    <>
      <InputText
        label="Chave NF-e"
        maxLength={50}
        placeholder=""
        name="num_chave_acesso"
        register={register}
        isError={!!errors.num_chave_acesso}
        control={control}
      />

      <TableContainer>
        <DataGrid
          rowsPerPageOptions={[5]}
          rows={logs}
          columns={columns}
          pageSize={5}
          disableColumnSelector
          disableColumnFilter
          disableSelectionOnClick
          localeText={{
            noRowsLabel: 'Nenhum registro encontrado...',
            columnMenuLabel: 'Menu',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Esconder',
            columnMenuUnsort: 'Não ordenar',
            columnMenuSortAsc: 'Ordernar ASC',
            columnMenuSortDesc: 'Ordernar DESC',
            columnMenuShowColumns: 'Mostrar columnas',
          }}
        />
      </TableContainer>
    </>
  );
};

export default Logs;
