import { Parcela } from '~/pages/EntradaNFE/protocols';

const removeKeys = ({ uuid, id, ...rest }: { uuid: string; id: string }) =>
  rest;

const isEqual = (obj1: any, obj2: any) => {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) return false;

  return obj1Keys.every((key) => obj1[key] === obj2[key]);
};

export const compareArrays = (
  array1: Parcela[],
  array2: Parcela[],
): boolean => {
  if (array1.length !== array2.length) return false;

  return array1.every((item1) => {
    const item1WithoutKeys = removeKeys(item1);
    return array2.some((item2) => isEqual(item1WithoutKeys, removeKeys(item2)));
  });
};
