import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Separator from '~/components/Separator';
import { CardCritica } from '../../components/CardCritica';
import { useEntradaNFE } from '../../EntradaNFEContext';
import { Container } from './styles';

export const Critica: React.FC = () => {
  const { criticas } = useEntradaNFE();

  return (
    <Container>
      <Separator labelText="Inconsistências" />
      {criticas.map((critica) => {
        return (
          <CardCritica
            key={critica.title}
            type={critica.type}
            title={critica.title}
            message={critica.message}
            show={critica.show}
            calculatedValue={critica.calculatedValue}
            fieldName={critica.fieldName}
          />
        );
      })}
    </Container>
  );
};
